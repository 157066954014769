import { type Batch } from '@autone/openapi-reorder';
import { type MainTranslationFunction } from '@autone/translations';

import { type APP_NAME } from '../../constant';

type ErrorCopy = { header: string; description: string };

export const getErrors = ({
  t,
}: {
  t: MainTranslationFunction<typeof APP_NAME>;
}): Record<
  //TODO: temporarily excluding INTERNAL_ERROR due to type error. Add back in when we work on this task
  Exclude<Batch['error_code'], null | 'UNKNOWN' | 'INTERNAL_ERROR'>,
  ErrorCopy
> => ({
  PRODUCT_NOT_FOUND: {
    header: t('error-page.product-not-found.header'),
    description: t('error-page.product-not-found.description'),
  },
  NO_SELECTED_PRODUCTS_WITHIN_SCOPE: {
    header: t('error-page.no-selected-products-within-scope.header'),
    description: t('error-page.no-selected-products-within-scope.description'),
  },
  NO_PRODUCTS_WITH_SALES_WITHIN_SCOPE: {
    header: t('error-page.no-product-with-sales-within-scope.header'),
    description: t('error-page.no-product-with-sales-within-scope.description'),
  },
  NO_PRODUCTS_WITH_SALES_WITHIN_BRAND_SCOPE: {
    header: t('error-page.no-products-with-sales-within-brand-scope.header'),
    description: t(
      'error-page.no-products-with-sales-within-brand-scope.description',
    ),
  },
  NO_PRODUCTS_WITH_SALES_WITHIN_SEASON_SCOPE: {
    header: t('error-page.no-selected-sales-within-season-scope.header'),
    description: t(
      'error-page.no-selected-sales-within-season-scope.description',
    ),
  },
  NO_PRODUCTS_WITH_SALES_WITHIN_EVENT_SCOPE: {
    header: t('error-page.no-products-with-sales-within-event-scope.header'),
    description: t(
      'error-page.no-products-with-sales-within-event-scope.description',
    ),
  },
  NO_PRODUCTS_WITH_SALES_WITHIN_DEPARTMENT_SCOPE: {
    header: t(
      'error-page.no-products-with-sales-within-department-scope.header',
    ),
    description: t(
      'error-page.no-products-with-sales-within-department-scope.description',
    ),
  },
  NO_PRODUCTS_WITH_SALES_WITHIN_SUB_DEPARTMENT_SCOPE: {
    header: t(
      'error-page.no-products-with-sales-within-sub-department-scope.header',
    ),
    description: t(
      'error-page.no-products-with-sales-within-sub-department-scope.description',
    ),
  },
});
