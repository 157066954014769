import { customBaseQuery } from '@autone/ui';
import { createApi } from '@reduxjs/toolkit/query/react';

const TAG_TYPES = [
  'Batch',
  'Batches',
  'Products',
  'MonthlyPackKPI',
  'SkuDaysStockOut',
  'Summary',
  'KeyMetricsKPI',
  'SkuProposal',
  'ReorderReasons',
  'Assortment',
  'StockOut',
  'SalesInventoryDetail',
  'InventoryDetail',
  'ReorderHistory',
  'ProductReasons',
  'BatchPagedOverview',
];

// Define a service using the base core URL and expected endpoints
export const reorderApi = createApi({
  reducerPath: 'reorderApi',
  tagTypes: TAG_TYPES,
  baseQuery: async (args, api, extraOptions) =>
    customBaseQuery(args, api, extraOptions, 'v2/reorder'),
  endpoints: () => ({}),
});
